import React from "react";
import { FileIcon, defaultStyles } from "react-file-icon";

export class Thumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      thumb: undefined,
    };
  }

  componentDidMount() {
    this.loadFile(this.props.file);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file) {
      this.loadFile(this.props.file);
    }
  }

  loadFile(file) {
    if (!file) {
      this.setState({ thumb: undefined });
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(file);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Update only if the file or thumbnail has changed
    return (
      nextProps.file !== this.props.file ||
      nextState.thumb !== this.state.thumb ||
      nextState.loading !== this.state.loading
    );
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;
    let extension = file?.name?.split(".").pop().toLowerCase();
    let imageFileExtension = ["jpeg", "jpg", "png"];

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>Loading...</p>;
    }

    if (!imageFileExtension.includes(extension)) {
      return (
        <div className="d-flex img-thumbnail mt-3 uploadeddoc">
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </div>
      );
    }

    return (
      <img
        src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        height={200}
        width={200}
      />
    );
  }
}
