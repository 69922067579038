// We speciify the name of the action as a variable
export const SET_USERDATA = "SET_USERDATA";
export const IS_LOADING = "IS_LOADING";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_SELECTED_FORM = "SET_SELECTED_FORM";
export const SET_TAB_NAME = "SET_TAB_NAME";
export const SET_CHILD_FORM = "SET_CHILD_FORM";
export const SET_SEAT_FORM = "SET_SEAT_FORM";
export const SET_LIST_FORM = "SET_LIST_FORM";
export const SET_LEAD_FORM = "SET_LEAD_FORM";
export const SET_EDGE_FORM = "SET_EDGE_FORM";
export const SET_ES_FORM = "SET_ES_FORM";
export const SET_EWE_FORM = "SET_EWE_FORM";
export const SET_PUBLIC_KEY = "SET_PUBLIC_KEY";
export const SET_CASE_NOTE = "SET_CASE_NOTE";
export const SET_EMPLOYER_CASE_NOTE = "SET_EMPLOYER_CASE_NOTE";
export const SET_PRIVATE_KEY = "SET_PRIVATE_KEY";
export const SET_EMPLOYER_FORM = "SET_EMPLOYER_FORM";
export const SET_WIST_FORM = "SET_WIST_FORM";
export const SET_SURVEY_FORM = "SET_SURVEY_FORM";
export const SET_CITIZENSHIP_FORM = "SET_CITIZENSHIP_FORM";
export const SET_CCT_FORM = "SET_CCT_FORM";
export const SET_EPA_FORM = "SET_EPA_FORM";
export const setUserData = (data) => {
  return {
    type: SET_USERDATA,
    payload: data,
  };
};
export const setDepartmentsData = (data) => {
  return {
    type: SET_DEPARTMENTS,
    payload: data,
  };
};
export const setLoader = (data) => {
  return {
    type: IS_LOADING,
    payload: data,
  };
};
export const setSelectedForm = (data) => {
  return {
    type: SET_SELECTED_FORM,
    payload: data,
  };
};
export const setChildForm = (data) => {
  return {
    type: SET_CHILD_FORM,
    payload: data,
  };
};
export const setSeatForm = (data) => {
  return {
    type: SET_SEAT_FORM,
    payload: data,
  };
};
export const setSurveyForm = (data) => {
  return {
    type: SET_SURVEY_FORM,
    payload: data,
  };
};
export const setLISTForm = (data) => {
  return {
    type: SET_LIST_FORM,
    payload: data,
  };
};
export const setLEADForm = (data) => {
  return {
    type: SET_LEAD_FORM,
    payload: data,
  };
};
export const setEDGEForm = (data) => {
  return {
    type: SET_EDGE_FORM,
    payload: data,
  };
};
export const setESForm = (data) => {
  return {
    type: SET_ES_FORM,
    payload: data,
  };
};
export const setEWEForm = (data) => {
  return {
    type: SET_EWE_FORM,
    payload: data,
  };
};
export const setTabName = (data) => {
  return {
    type: SET_TAB_NAME,
    payload: data,
  };
};
export const setEmployerForm = (data) => {
  return {
    type: SET_EMPLOYER_FORM,
    payload: data,
  };
};
export const setWistForm = (data) => {
  console.log("data=>::", data);
  return {
    type: SET_WIST_FORM,
    payload: data,
  };
};
export const setCCTForm = (data) => {
  console.log("data=>::", data);
  return {
    type: SET_CCT_FORM,
    payload: data,
  };
};
export const setEPAForm = (data) => {
  console.log("data=>::", data);
  return {
    type: SET_EPA_FORM,
    payload: data,
  };
};
export const setCitizenshipForm = (data) => {
  console.log("data=>", data);
  return {
    type: SET_CITIZENSHIP_FORM,
    payload: data,
  };
};
export const setPublicKey = (data) => {
  return {
    type: SET_PUBLIC_KEY,
    payload: data,
  };
};
export const setPrivateKey = (data) => {
  return {
    type: SET_PRIVATE_KEY,
    payload: data,
  };
};
export const setCaseNote = (data) => {
  return {
    type: SET_CASE_NOTE,
    payload: data,
  };
};
export const setEmployerCaseNote = (data) => {
  return {
    type: SET_EMPLOYER_CASE_NOTE,
    payload: data,
  };
};
// export const getUpdateCount = (count)=> dispatch =>{
//     count += 1;
//     dispatch(setUpdateCount(count));
// }
