import React, { lazy } from "react";
import { Role } from "../../_helpers";

// Lazy load all components
const EmployerContact = lazy(() => import("../../pages/EmployerContact"));
const OrientationRegistration = lazy(() =>
  import("../../pages/OrientationRegistration")
);
const Search = lazy(() => import("../../pages/Search"));
const ClientDashboard = lazy(() => import("../../pages/user/ClientDashboard"));
const UserProfile = lazy(() => import("../../pages/user/UserProfile"));
const WestProfile = lazy(() => import("../../pages/user/WestProfile"));
const UserDashboardPage = lazy(() =>
  import("../../pages/user/UserDashboardPage")
);
const SettlementServices = lazy(() =>
  import("../../pages/user/SettlementServices")
);
const Reports = lazy(() => import("../../pages/user/Reports"));
const changePassword = lazy(() => import("../../pages/user/changePassword"));
const Inbox = lazy(() => import("../../pages/user/inbox"));
const Import = lazy(() => import("../../pages/user/import"));
const AdminDashboardPage = lazy(() =>
  import("../../pages/admin/AdminDashboardPage")
);
const CreateUser = lazy(() => import("../../pages/admin/CreateUser"));
const Users = lazy(() => import("../../pages/admin/User"));
const Departments = lazy(() => import("../../pages/admin/Department"));
const CreateDepartment = lazy(() =>
  import("../../pages/admin/CreateDepartment")
);
const Referrals = lazy(() => import("../../pages/admin/Referral"));
const CreateReferral = lazy(() => import("../../pages/admin/CreateReferral"));
const CreateSupport = lazy(() => import("../../pages/admin/CreateSupport"));
const DeletionForm = lazy(() => import("../../pages/admin/DeletionForm"));
const Schedule = lazy(() => import("../../pages/user/Schedule"));
const WestCommunications = lazy(() => import("../../pages/WestCommunications"));
const SharedCalendarInbox = lazy(() =>
  import("../../pages/SharedCalendarInbox")
);
const childList = lazy(() => import("../../pages/user/CNC/childList"));
const ChildrenServices = lazy(() =>
  import("../../pages/user/CNC/ChildrenServices")
);
const ImportHistory = lazy(() => import("../../pages/admin/ImportHistory"));
const SeatServices = lazy(() => import("../../pages/user/SEAT/SeatServices"));
const SeatList = lazy(() => import("../../pages/user/SEAT/SeatList"));
const ListServices = lazy(() => import("../../pages/user/LIST/ListServices"));
const LISTList = lazy(() => import("../../pages/user/LIST/LISTList"));
const LeadServices = lazy(() => import("../../pages/user/LEAD/LeadServices"));
const LEADList = lazy(() => import("../../pages/user/LEAD/LEADList"));
const EdgeServices = lazy(() => import("../../pages/user/EDGE/EdgeServices"));
const EDGEList = lazy(() => import("../../pages/user/EDGE/EDGEList"));
const EsServices = lazy(() => import("../../pages/user/ES/EsServices"));
const WorkCalendarInbox = lazy(() =>
  import("../../pages/user/WorkCalendarInbox.jsx")
);
const EweServices = lazy(() => import("../../pages/user/EWE/EweServices"));
const Payroll = lazy(() => import("../../pages/user/Payroll"));
const PayrollAdmin = lazy(() => import("../../pages/user/PayrollAdmin"));
const PayrollAdminView = lazy(() =>
  import("../../pages/user/PayrollAdminView")
);
const PayrollLeaveView = lazy(() =>
  import("../../pages/user/PayrollLeaveView")
);
const PayrollMasterView = lazy(() =>
  import("../../pages/user/PayrollMasterView")
);
const PayrollLieuView = lazy(() => import("../../pages/user/PayrollLieuView"));

const routes = [
  // User Routes
  {
    exact: true,
    path: "/",
    role: [Role.User, Role.Manager],
    Component: ClientDashboard,
  },
  {
    exact: true,
    path: "/search-client",
    role: [Role.User, Role.Manager],
    Component: Search,
  },
  {
    exact: true,
    path: "/employer-contact",
    role: [Role.User, Role.Manager],
    Component: EmployerContact,
  },
  {
    exact: true,
    path: "/orientation-registration",
    role: [Role.User, Role.Manager],
    Component: OrientationRegistration,
  },
  {
    exact: true,
    path: "/profile",
    role: [Role.User, Role.Manager],
    Component: UserProfile,
  },
  {
    exact: true,
    path: "/westprofile",
    role: [Role.User, Role.Manager],
    Component: WestProfile,
  },
  {
    exact: true,
    path: "/analytics",
    role: [Role.User, Role.Manager],
    Component: UserDashboardPage,
  },
  {
    exact: true,
    path: "/west-communications",
    role: [Role.User, Role.Manager],
    Component: WestCommunications,
  },
  {
    exact: true,
    path: "/west-calendar",
    role: [Role.User, Role.Manager],
    Component: SharedCalendarInbox,
  },
  {
    exact: false,
    path: "/settlement-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: SettlementServices,
  },
  {
    exact: false,
    path: "/children-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: ChildrenServices,
  },
  {
    exact: false,
    path: "/seat-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: SeatServices,
  },
  {
    exact: false,
    path: "/list-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: ListServices,
  },
  {
    exact: false,
    path: "/lead-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: LeadServices,
  },
  {
    exact: false,
    path: "/edge-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: EdgeServices,
  },
  {
    exact: false,
    path: "/es-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: EsServices,
  },
  {
    exact: false,
    path: "/ewe-services/:mode?/:formId?",
    role: [Role.User, Role.Manager],
    Component: EweServices,
  },
  {
    exact: false,
    path: "/reports",
    role: [Role.User, Role.Manager],
    Component: Reports,
  },
  {
    exact: false,
    path: "/inbox",
    role: [Role.User, Role.Manager],
    Component: Inbox,
  },
  {
    exact: false,
    path: "/import",
    role: [Role.User, Role.Manager],
    Component: Import,
  },
  {
    exact: false,
    path: "/childList",
    role: [Role.User, Role.Manager],
    Component: childList,
  },
  {
    exact: false,
    path: "/seatList",
    role: [Role.User, Role.Manager],
    Component: SeatList,
  },
  {
    exact: false,
    path: "/LISTList",
    role: [Role.User, Role.Manager],
    Component: LISTList,
  },
  {
    exact: false,
    path: "/LEADList",
    role: [Role.User, Role.Manager],
    Component: LEADList,
  },
  {
    exact: false,
    path: "/EDGEList",
    role: [Role.User, Role.Manager],
    Component: EDGEList,
  },
  {
    exact: true,
    path: "/work-calendar",
    role: [Role.User, Role.Manager],
    Component: WorkCalendarInbox,
  },
  {
    exact: true,
    path: "/payroll",
    role: [Role.User, Role.Manager],
    Component: Payroll,
  },
  {
    exact: true,
    path: "/payroll-admin",
    role: [Role.User, Role.Manager],
    Component: PayrollAdmin,
  },
  {
    exact: true,
    path: "/payroll-admin-view/:id",
    role: [Role.User, Role.Manager],
    Component: PayrollAdminView,
  },
  {
    exact: true,
    path: "/payroll-lieu-view/:id",
    role: [Role.User, Role.Manager],
    Component: PayrollLieuView,
  },
  {
    exact: true,
    path: "/payroll-leave-view/:id",
    role: [Role.User, Role.Manager],
    Component: PayrollLeaveView,
  },
  {
    exact: true,
    path: "/payroll-master-view/:id",
    role: [Role.User, Role.Manager],
    Component: PayrollMasterView,
  },
  {
    exact: false,
    path: "/schedule",
    role: [Role.User, Role.Manager],
    Component: Schedule,
  },
  {
    exact: false,
    path: "/change-password",
    role: [Role.User, Role.Manager],
    Component: changePassword,
  },

  // Admin routes
  {
    exact: true,
    path: "/admin",
    role: [Role.Admin],
    Component: AdminDashboardPage,
  },
  {
    exact: false,
    path: "/admin/users",
    role: [Role.Admin],
    Component: Users,
  },
  {
    exact: false,
    path: "/admin/deletion",
    role: [Role.Admin],
    Component: DeletionForm,
  },
  {
    exact: false,
    path: "/admin/user/create",
    role: [Role.Admin],
    Component: CreateUser,
  },
  {
    exact: false,
    path: "/admin/user/edit/:id?",
    role: [Role.Admin],
    Component: CreateUser,
  },
  {
    exact: false,
    path: "/admin/departments",
    role: [Role.Admin],
    Component: Departments,
  },
  {
    exact: false,
    path: "/admin/department/create",
    role: [Role.Admin],
    Component: CreateDepartment,
  },
  {
    exact: false,
    path: "/admin/department/edit/:id?",
    role: [Role.Admin],
    Component: CreateDepartment,
  },
  {
    exact: false,
    path: "/admin/change-password",
    role: [Role.Admin],
    Component: changePassword,
  },
  {
    exact: true,
    path: "/admin/referrals/supports",
    role: [Role.Admin],
    Component: CreateSupport,
  },
  {
    exact: true,
    path: "/admin/referrals",
    role: [Role.Admin],
    Component: Referrals,
  },
  {
    exact: true,
    path: "/admin/import-history",
    role: [Role.Admin],
    Component: ImportHistory,
  },
  {
    exact: true,
    path: "/admin/referral/create",
    role: [Role.Admin],
    Component: CreateReferral,
  },
  {
    exact: true,
    path: "/admin/referral/edit/:id?",
    role: [Role.Admin],
    Component: CreateReferral,
  },
];

export default routes;
