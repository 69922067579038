// const crypto = require("crypto");
import { Crypt } from 'hybrid-crypto-js';
import configureStore from '../../../redux-store/store';

const store = configureStore()
let state
let unsubscribe = store.subscribe(function(){
     state = store.getState();
  })
let crypt = new Crypt();

function split(input, len) {
    return input.match(new RegExp('.{1,' + len + '}(?=(.{' + len + '})+(?!.))|.{1,' + len + '}$', 'g'))
}
// let publicKey = state?.publicKey
// let privateKey = state?.privateKey
let encryptStringWithRsaPublicKey = function(toEncrypt,key) {
    let splittedString = split(toEncrypt, 8000);
    let encryptedStringArr = [];
    for(let i=0; i < splittedString.length; i++){
        let encrypted = crypt.encrypt(key, splittedString[i]);
        encryptedStringArr.push(encrypted);
    }

    return encryptedStringArr;
};

let decryptStringWithRsaPrivateKey = function(toDecrypt,key='') {
    try{
        let requestString = [];
        for(let i=0;i<toDecrypt.length;i++){
            let decrypted = crypt.decrypt(key, toDecrypt[i]);
            requestString.push(decrypted.message);
        }
        return requestString.join('');

    }catch(ex){
        console.log(ex);
    }

};

export default  {
    encryptResponse :encryptStringWithRsaPublicKey ,
    decryptRequest :decryptStringWithRsaPrivateKey
}

// let a = encryptStringWithRsaPublicKey("hello", "public.pem")
// let b = decryptStringWithRsaPrivateKey(a, "private.pem");
// console.log(b)
