import { BehaviorSubject } from 'rxjs';

import config from '../config';
import { handleResponse } from '../_helpers';
import EncDec from '../e2e/e2e_functions/index'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const currentDepartmentSubject = new BehaviorSubject(localStorage.getItem('currentDepartment'));

export const authenticationService = {
    login,
    logout,
    
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    currentDepartment: currentDepartmentSubject.asObservable(),
    get currentDepartmentValue () { return currentDepartmentSubject.value },
    changeDepartment,
    changeUser
};

function changeUser(user){
    localStorage.setItem('currentUser', JSON.stringify({...currentUserSubject.getValue(),...user}));
    currentUserSubject.next({...currentUserSubject.getValue(),...user});
    // window.location.href = "/";
}
function changeDepartment(departmentId){
    localStorage.setItem('currentDepartment', departmentId);
    currentDepartmentSubject.next(departmentId);
    window.location.href = "/";
}
function encData(data) {
    let isenc =localStorage.getItem('isenc')?parseInt(localStorage.getItem('isenc')):0
    if (isenc == 1) {        
        return JSON.stringify({
            enc_string:EncDec.encryptResponse(
                JSON.stringify(data)
            )
        })
    } else {
        return JSON.stringify(data)
    }
}
function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',isenc:localStorage.getItem('isenc') || 0 },
        body: encData({email,password})
    };

    return fetch(`${config.apiUrl}/api/v1/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user.Data));
            currentUserSubject.next(user.Data);
            //store department selection default1
            localStorage.setItem('currentDepartment', user.Data?.departments[0]);
            currentDepartmentSubject.next(user.Data?.departments[0]);

            return user.Data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentDepartment');
    currentUserSubject.next(null);
    currentDepartmentSubject.next(null);
}
