import { authenticationService } from '../_services';
import EncDec from '../e2e/security/e2e_functions/index';


export function handleResponse(response, key) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response?.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from ap
                authenticationService.logout();
                window.location.reload(true);
            }
            const error = (data && data.Message) || response?.statusText;
            return Promise.reject(error);
        }
        if (data.hasOwnProperty('Data') && data.isEnc === true) {
            console.log('data: ', data);
            data.Data = JSON.parse(EncDec.decryptRequest(data.Data, key))
        }
        return data;
    });


}
