import React from "react";
import { Route, Redirect } from "react-router-dom";

import { authenticationService } from "../_services";
import { Role } from "../_helpers";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      {
        console.log("roles: ", roles);
      }
      const currentUser = authenticationService.currentUserValue;
      console.log("currentUser: ", currentUser);
      if (!currentUser) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      console.log(
        "<================roles && roles.role !== currentUser.role: ===================>",
        roles,
        roles.role !== currentUser.role
      );
      if (roles && !roles.role.includes(currentUser.role)) {
        return currentUser.role === Role.Admin ? (
          <Redirect to={{ pathname: "/admin" }} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        );
      }
      return <Component {...props} />;
    }}
  />
);
