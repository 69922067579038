export const Role = {
    Admin: 1,
    User: 2,
    Manager: 3
}

export const RoleNames = {
    [Role.Admin]: "Admin",
    [Role.User]: "User",
    [Role.Manager]: "Manager"
};