import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import { Role } from "./_helpers";
import { authenticationService } from "./_services";
import jQuery from "jquery";
import { setPrivateKey, setPublicKey } from "./redux-store/action";
import RouterComponent from "./components/Router/RouterComponent";
import config from "./config";
window.jQuery = jQuery;

function mapStateToProps(state) {
  return {
    private_key: state.private_key,
    public_key: state.public_key,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPublicKey: (data) => {
      dispatch(setPublicKey(data));
    },
    setPrivateKey: (data) => {
      dispatch(setPrivateKey(data));
    },
  };
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false,
    };
  }
  render() {
    if (!this.props.public_key) {
      return <div></div>;
    } else {
      return (
        <RouterComponent
          currentUser={this.state.currentUser}
          isAdmin={this.state.isAdmin}
        />
      );
    }
  }
  componentDidMount() {
    console.log('Debugger On: ', process.env.REACT_APP_DEBUG);
    if (process.env.REACT_APP_DEBUG && process.env.REACT_APP_DEBUG !== 'true') {
      console.log = function () { }
    }

    if (!this.props.public_key) {
		// this.props.setPrivateKey(private_key);
		// this.props.setPublicKey(public_key);

		// fetch(`/security/public_client.pem`, { mode: "no-cors" })
		// fetch(`${config.apiUrl}/security/public-pem`, { mode: "no-cors" })
        // .then((response) => {
		// 	console.log('response: ', response);
		// 	console.log('response.body: ', response.body);
		// 	return response.text()
		// })
        // .then((data) => {
		// 	// fetch(`/security/private_client.pem`, { mode: "no-cors" })
		// 	fetch(`${config.apiUrl}/security/private-pem`, { mode: "no-cors" })
        //     .then((response1) => {
		// 		console.log('response1: ', response1);
		// 		console.log('response.body: ', response1.body);
		// 		return response1.text()
		// 	})
        //     .then((data1) => {
		// 		console.log('data1: ', data1);
		// 		console.log('data: ', data);
        //       this.props.setPrivateKey(data1);
        //       this.props.setPublicKey(data);
        //     }).catch(error => {
		// 		console.log('INNER error: ', error);
		// 	})
        // })
        // .catch((error) => {
		// 	console.error("error : ", error)
		// });

		fetch(`${config.apiUrl}/security/public-pem`, {
			headers: {
			  Accept: 'application/octet-stream'
			},
		  })
			.then(response => {
			  // Check if the response is successful
			  console.log('response: ', response);
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }
			  
			  // Return the arrayBuffer() promise
			  return response.arrayBuffer();
			})
			.then(arrayBuffer => {
				console.log('arrayBuffer: ', arrayBuffer);
			  // Convert arrayBuffer to Uint8Array
			  const byteArray = new Uint8Array(arrayBuffer);
			  
			  // Convert Uint8Array to string (assuming it's text data)
			  const data = new TextDecoder().decode(byteArray);
			  
			  console.log('Received data:', data);
			  this.props.setPublicKey(data);
		  
			  // Handle the data as needed
			  // Example: set it to component state or pass it to a function
			})
			.catch(error => {
			  console.error('Error fetching or reading octet-stream:', error);
			});

		fetch(`${config.apiUrl}/security/private-pem`, {
			headers: {
			  Accept: 'application/octet-stream'
			},
		  })
			.then(response => {
			  // Check if the response is successful
			  console.log('response: ', response);
			  if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			  }
			  
			  // Return the arrayBuffer() promise
			  return response.arrayBuffer();
			})
			.then(arrayBuffer => {
				console.log('arrayBuffer: ', arrayBuffer);
			  // Convert arrayBuffer to Uint8Array
			  const byteArray = new Uint8Array(arrayBuffer);
			  
			  // Convert Uint8Array to string (assuming it's text data)
			  const data = new TextDecoder().decode(byteArray);
			  
			  console.log('Received data:', data);
			  this.props.setPrivateKey(data);
		  
			  // Handle the data as needed
			  // Example: set it to component state or pass it to a function
			})
			.catch(error => {
			  console.error('Error fetching or reading octet-stream:', error);
			});
    }
    authenticationService.currentUser.subscribe((x) =>
      this.setState({
        currentUser: x,
        isAdmin: x && x.role === Role.Admin,
      })
    );

    let host = window.location.origin;
    let cssFiles = [];
    cssFiles.map((t) => {
      let link = document.createElement("link");
      link.href = host + t;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      return true;
    });
    let scripts = [
      host + "/assets/plugins/jquery/jquery.min.js",
      // 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js',
      "https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.4-beta.33/bindings/inputmask.binding.min.js",
      host + "/assets/plugins/popper/popper.min.js",
      host + "/assets/plugins/bootstrap/js/bootstrap.min.js",
      host + "/assets/js/jquery.slimscroll.js",
      host + "/assets/js/waves.js",
      host + "/assets/js/sidebarmenu.js",
      host + "/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js",
      host + "/assets/plugins/sparkline/jquery.sparkline.min.js",
      host + "/assets/plugins/styleswitcher/jQuery.style.switcher.js",
      host + "/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js",
      host + "/assets/plugins/select2/dist/js/select2.full.min.js",
      host + "/assets/js/custom.min.js",
    ];

    scripts.map((t) => {
      let script = document.createElement("script");
      script.src = t;
      script.async = false;
      document.body.appendChild(script);
      return true;
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
